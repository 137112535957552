/* global popupGalleryData, PhotoSwipeUI_Default */

//$(function(){
    $(".niri[data-index]").addClass ("active").click (function() {
        var gallery = $(this).data ("gallery"),
            index   = $(this).data ("index"),
            items   = popupGalleryData[gallery],
            options = {index: index, shareEl: false};
        
        if (items && items.length)
            (new PhotoSwipe ($(".pswp")[0], PhotoSwipeUI_Default, items, options)).init();
    });
//});