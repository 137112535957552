$("a[href^='#']", ".ccm-page").click (function (e) {
    e.preventDefault();
    var selector = $(this).attr ("href"),
        $target  = $(selector);
    
    if ($target.length)
        $("html, body").animate(
            {scrollTop: $target.offset().top},
            500//,
//            function() {document.location.hash = selector;}
        );
})

//function isMobile() {
//    var id   = "#qnddmcd",
//        $div = $(id);
//    
//    if (!$div.length)
//        $div = $("<div>")   .attr ("id", id)
//                            .addClass ("visible-xs")
//                            .appendTo ("body");
//    
//    return $div.is (":visible");
//}