!function() {
    var header      = $(".ccm-page>header")[0],
        headingSize = $(header).outerHeight(),
        scrolled    = $(window).scrollTop() > headingSize;
    
    $(".toggle-mobile-nav", header).click (function() {
        $("body").toggleClass ("nav-open");
    });

    $(".toggle-share", header).click (function() {
        $(".addthis_inline_share_toolbox", header).toggleClass ("open");
    });
    
    $("body").toggleClass ("scrolled", scrolled);

    $(window).scroll (function() {
        var newScrolled = $(window).scrollTop() > headingSize;

        if (newScrolled !== scrolled) {
            scrolled = newScrolled;
            $("body").toggleClass ("scrolled", scrolled);
        }
    });
}();
